import { useAtom } from 'jotai'
import Dialog from '@/components/dialog'
import Button from '@/components/button'
import { insufficientDialogOpenAtom } from '@/atoms'

export default function InsufficientDialog() {
  const [insufficientDialogOpen, setInsufficientOpen] = useAtom(insufficientDialogOpenAtom)

  const close = () => {
    setInsufficientOpen(false)
  }

  return (
    <Dialog
      open={insufficientDialogOpen}
      title='Insufficient credits'
      titleClassName='mb-3'
      className='gap-0 w-100 md:w-100 p-6'
      footerClassName='mt-[30px]'
      footer={null}
      onOpenChange={(open) => setInsufficientOpen(open)}
    >
      <div className='w-[352px] mb-8 text-text-subdued'>You don’t have enough credits for this creation.</div>
      <div className='flex gap-3 w-full'>
        <Button variant='primary' className='flex-1 rounded-md' onClick={close}>
          Confirm
        </Button>
      </div>
    </Dialog>
  )
}
