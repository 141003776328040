'use client'

import Dialog from '@/components/dialog'
import { cls, openNewTab, preventDefaultAndStopPropagation } from '@/utils'
import { useCallback, useMemo, useState } from 'react'
import Button from '../button'
import Link from '../link'
import { usePathname } from 'next/navigation'
import { useAtom } from 'jotai'
import { endofJourneyDialogOpenAtom } from '@/atoms'

const blacklist = [/^\/auth\//, /^\/onboarding/]

export function EndOfTheJourney() {
  const [showDialog, setShowDialog] = useAtom(endofJourneyDialogOpenAtom)
  const pathname = usePathname()

  const isBlacklisted = useMemo(() => blacklist.some((pattern) => pattern.test(pathname)), [pathname])

  const handleClose = useCallback(() => {
    setShowDialog(false)
    closed = true
  }, [setShowDialog])

  const moreDetails = useCallback(
    (e: any) => {
      // TODO: Add a link to the refund policy
      preventDefaultAndStopPropagation(e)
      openNewTab('/')
      handleClose()
    },
    [handleClose],
  )

  if (isBlacklisted || closed) {
    return null
  }

  return (
    <Dialog
      open={!!showDialog}
      title='Thanks for being a part of our journey'
      titleClassName='mb-4 font-bold text-heading-2xl'
      className={cls('gap-0 w-[343px] md:w-[578px]')}
      footerClassName='mt-[30px]'
      footer={null}
      onOpenChange={handleClose}
    >
      <div className='relative w-full flex flex-col gap-8 text-text tracking-15'>
        <div className='w-full flex flex-col gap-4'>
          <div>
            Thanks for being a part of our journey. Our entire Haiper team believes there is no more exciting space than
            AI video and images, which is why we are deeply saddened to shutdown our webapp as we pivot to supporting
            our Enterprise business.
          </div>
          <div className='w-full flex flex-col gap-1' aria-label='stage-1'>
            <div className='text-heading-md font-bold tracking-32'>Here’s what you need to know:</div>
            <ul className='w-full flex flex-col list-inside list-disc pl-2'>
              <li>Generation is now unavailable – new tasks can no longer be created.</li>
              <li>Keep your content – you can download your past creations anytime before the website shuts down.</li>
              <li>
                Refunds for Haiper members – any unused subscription time will be automatically refunded to your
                original payment method.
              </li>
            </ul>
          </div>
          <div>We can't thank you enough for your support and understanding during this transition!</div>
        </div>
        <Button variant='primary' className='w-full h-10' onClick={handleClose}>
          Confirm
        </Button>
      </div>
    </Dialog>
  )
}
