'use client'

import Dialog from '@/components/dialog'
import { cls } from '@/utils'
import useAmplitude from '@/hooks/useAmplitude'
import { subscriptionDialogContentAtom } from '@/atoms'
import { useAtom } from 'jotai'
import { useCallback, useEffect, useMemo } from 'react'
import { ErrorCodeEnum } from '@/types'
import useActivePlan from '@/hooks/useActivePlan'
import Button from '../button'

export function SubscriptionDialog() {
  const { track } = useAmplitude()
  const [subscriptionDialogContent, setSubscriptionDialogContent] = useAtom(subscriptionDialogContentAtom)

  const { data: activePlan, loading } = useActivePlan()

  const handleClose = useCallback(() => {
    setSubscriptionDialogContent(null)
  }, [setSubscriptionDialogContent])

  useEffect(() => {
    if (subscriptionDialogContent) {
      track('view:membership:subscription-required', {
        code: subscriptionDialogContent.code ?? '',
        message: subscriptionDialogContent.message,
      })
    }
  }, [track, subscriptionDialogContent])

  const { title, content } = useMemo(() => {
    if (loading) {
      return {
        title: '',
        content: '',
      }
    }
    if (subscriptionDialogContent?.code === ErrorCodeEnum.QUOTA_EXCEEDED) {
      return {
        title: 'You\'ve hit your daily generation limit',
        content: 'Take a break and come back tomorrow for more creativity.',
      }
    }

    if (subscriptionDialogContent?.code === ErrorCodeEnum.RATE_EXCEEDED) {
      return {
        title: 'Too many concurrent creations',
        content:
          'You’ve reached the current generation limit. Please wait for some tasks to finish before starting new ones.',
      }
    }

    return {
      title: '',
      content: '',
    }
  }, [subscriptionDialogContent, loading])

  return (
    <Dialog
      open={!!subscriptionDialogContent && !loading && !!title}
      title={<span className='text-heading-lg font-bold tracking-45'>{title}</span>}
      titleClassName='mb-1 font-bold text-heading-lg'
      className={cls('gap-0 w-[343px] md:w-100')}
      footerClassName='mt-[30px]'
      footer={null}
      onOpenChange={handleClose}
    >
      <div className='relative w-full flex flex-col gap-8 text-text tracking-15'>
        <div className='text-body-md tracking-15 mt-2 leading-5 text-text-subdued'>{content}</div>
        <Button variant='primary' className='w-full h-10' onClick={handleClose}>
          Confirm
        </Button>
      </div>
    </Dialog>
  )
}
